import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './item.css'
import { AuthContext } from '../../../providers/AuthProvider';
import { RoomContext } from '../../../providers/RoomProvider'
import publishMessage from '../../../utils/publishMessage'

const Item = props => {
  const { room, isActive } = props
  const { name, color } = room
  const { setActiveRoomId, setRooms } = useContext(RoomContext)
  const { user } = useContext(AuthContext)

  return (
    <li
      key={room.id}
      onClick={() => {
        setActiveRoomId(room.id)
        setRooms(prevState => prevState.map(x => x.id === room.id ? { ...room, unreadCount: 0 } : x))
        localStorage.setItem(room.id, (room.messagesCount || 0).toString())

          publishMessage({
              table: 'page_views',
              data: {
                  datetime: '',
                  path: `/app/chat/${room.id}`,
                  user_id: user?.metadata?.externalId,
              },
          })
      }}
      className={isActive ? 'active' : ''}
      style={{ background: isActive ? color : `linear-gradient(to right, ${color} 50%, #fff 50%)` }}
    >
      <span className="color" style={{ background: color }}></span>
      <span className="name">{name}</span>
      {
        room.public &&
          <span className={`tag ${!room.locked && 'active'}`}>
            {
              room.locked ?
                room.messagesCount > 0 ? 'PAST': 'COMING SOON'
              :
                'ACTIVE'
            }
          </span>
      }
      {
        room.unreadCount > 0 && <span className="dot">{room.unreadCount}</span>
      }
      <span className="icon">
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </li>
  )
}

export default Item
