import React, { useEffect, useContext, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import './index.css'

import fire from '../../../firebase'
import Item from './item'

import { AuthContext } from '../../../providers/AuthProvider'
import { RoomContext } from '../../../providers/RoomProvider'

const ChatSidebar = () => {
  const { localStorage } = window

  const { id } = useParams()
  const { senderId, sender, isCollapse, toggleMenu, setLoading } = useContext(AuthContext)
  const { rooms, setRooms, activeRoom, setActiveRoomId } = useContext(RoomContext)

  const activeRoomRef = useRef()
  activeRoomRef.current = activeRoom

  useEffect(() => {
    const updateRooms = (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const { doc, type } = change
        const data = doc.data()

        const room = {
          id: doc.id,
          color: data.color || '#f9d8d6',
          ...data,
        }

        if (room.public) {
          if (!room.mixer) {
            room.name = `Q&A with ${room.name}`
          }
        } else {
          if (room.users) {
            room.name = Object.keys(room.users).filter((uid) => uid !== senderId).map((uid) => room.users[uid]).join(' & ')
          }

          let unreadCount = (data.messagesCount || 0) - (parseInt(localStorage.getItem(doc.id)) || 0)

          if (activeRoomRef.current.id === doc.id) {
            unreadCount = 0
            localStorage.setItem(doc.id, (data.messagesCount || 0).toString())
          }

          room.unreadCount = unreadCount
        }

        if (type === 'added') {
          setRooms((prevState) =>
            [...prevState, room]
          )
        }

        if (type === 'modified') {
          setRooms((prevState) =>
            prevState.map((x) => (x.id === room.id ? room : x))
          )
        }

        if (type === 'removed') {
          setRooms((prevState) =>
            prevState.filter((x) => x.id !== doc.id)
          )
        }

        const activeRoom = activeRoomRef?.current

        if (!activeRoom.id && id === room.id) {
          setActiveRoomId(room.id)
        }
      })

      setLoading(false)
    }

    fire
      .chatRef()
      .where('public', '==', true)
      .orderBy('locked', 'desc')
      .onSnapshot(updateRooms)

    fire
      .chatRef()
      .where(`users.${senderId}`, '!=', false)
      .onSnapshot(updateRooms)
  }, [])

  useEffect(() => {
    let unreadMessages = 0

    rooms.forEach(room => (unreadMessages += room.unreadCount || 0))

    window.top.postMessage(
      {
        event: 'unreadMessages',
        unreadMessages,
      },
      '*'
    )
  }, [rooms])

  const activeRoomFilter = x => x.unreadCount > 0 || (x.public && !x.locked)

  const activeRooms = rooms.filter(activeRoomFilter)
  const privateRooms = rooms.filter(x => !x.public && !activeRoomFilter(x))
  const qaRooms = rooms.filter(x => !!x.public && !x.mixer && !activeRoomFilter(x))
  const mixerRooms = rooms.filter(x => !!x.public && x.mixer && !activeRoomFilter(x))

  return (
    <nav id="sidebar" className={`collapse ${isCollapse && "active"}`}>
      <div className="sidebar-header">
        <h3>
          JOIN THE DISCUSSION
          <span className="sidebar-collapse-close" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </h3>
      </div>

      {
        activeRooms.length > 0 &&
          <div className="sidebar-subheader">
          <h4>Active Chats</h4>
          </div>
      }

      <ul className="list-unstyled components">
        {
          activeRooms
            .sort((a, b) => b.unreadCount - a.unreadCount)
            .sort((a, b) => (b.id === activeRoom.id) - (a.id === activeRoom.id))
            .map(room => <Item key={room.id} room={room} isActive={room.id === activeRoom.id} />)
        }
      </ul>

      {
        privateRooms.length > 0 &&
          <div className="sidebar-subheader">
            <h4>Direct Messages</h4>
          </div>
      }

      <ul className="list-unstyled components">
        {
          privateRooms
            .sort((a, b) => b.unreadCount - a.unreadCount)
            .map(room => <Item key={room.id} room={room} isActive={false} />)
        }
      </ul>

      {
        qaRooms.length > 0 &&
          <div className="sidebar-subheader">
            <h4>Q&A With Speakers</h4>
          </div>
      }

      <ul className="list-unstyled components">
        {
          qaRooms
            .filter(room => room.id !== activeRoom.id)
            .sort((a, b) => a.locked - b.locked)
            .map(room => <Item key={room.id} room={room} isActive={false} />)
        }
      </ul>

      {
        mixerRooms.length > 0 &&
        <div className="sidebar-subheader">
          <h4>Networking Mixers</h4>
        </div>
      }

      <ul className="list-unstyled components">
        {
          mixerRooms
            .sort((a, b) => a.locked - b.locked)
            .map(room => <Item key={room.id} room={room} isActive={false} />)
        }
      </ul>
    </nav>
  )
}

export default ChatSidebar
