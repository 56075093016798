import crypto from "crypto";

const GC_KEY = process.env.REACT_APP_GC_KEY || '';

const publishMessage = async(data) => {
    try {
        // console.log("awts GC_KEY: ", GC_KEY);
        // console.log("awts PUBSUB: ", data);

        if (!GC_KEY) return;

        data.data.datetime = new Date().toISOString().replace('T', ' ').split('.')[0];
        const nonce = Date.now();

        // it is important that the properties are sorted alphabetically
        const dataSignature = {
            key: GC_KEY,
            messages: [ data ],
            nonce,
        };

        const xhr = new XMLHttpRequest();
        xhr.open(
            'POST',
            'https://us-central1-istackmanila-playground.cloudfunctions.net/portal-pubsub',
        );
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        xhr.send(JSON.stringify({
            host: window.location.hostname,
            messages: dataSignature.messages,
            nonce: dataSignature.nonce,
            signature: crypto.createHmac("sha256", GC_KEY)
                .update(JSON.stringify(dataSignature))
                .digest("hex")
        }));
    }
    catch (e) {
        // console.log("awts pubsub error: ", e);
    }

};

export default publishMessage;
