import React, { useContext } from 'react'

import Loading from '../Loading'
import ChatRoom from './ChatRoom'
import ChatSidebar from './ChatSidebar'

import { RoomContext } from '../../providers/RoomProvider'
import { AuthContext } from '../../providers/AuthProvider'

function Chat() {
  const { loading } = useContext(AuthContext)
  const { activeRoom, activeThread } = useContext(RoomContext)

  return (
    <>
      { loading && <Loading />}
      <div className="wrapper">
        <ChatSidebar />
        <ChatRoom room={activeRoom} />
        { !!activeThread && <ChatRoom room={activeThread.room} threadMessage={activeThread.message} isThread={!!activeThread} /> }
        {
          !activeRoom.id && <h2 style={{ padding: '15% 20%', opacity: 0.75, width: '100%', textAlign: 'center' }}>⬅️ &nbsp;Welcome to the Ad World Conf Chat! Please select a Q&A to join or find someone from the Networking tab to chat with.</h2>
        }
      </div>
    </>
  )
}

export default Chat
