import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Chat from './Chat'
import Admin from './Admin'
import AuthProvider from '../providers/AuthProvider'
import AdminProvider from '../providers/AdminProvider'
import RoomProvider from '../providers/RoomProvider'

function App() {  
  return (
    <BrowserRouter>
      <AuthProvider>
        <AdminProvider>
          <RoomProvider>
            <Switch>
              <Route exact path='/admin' component={Admin} />
              <Route exact path='/:id?' component={Chat} />
            </Switch>
          </RoomProvider>
        </AdminProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
