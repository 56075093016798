import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Picker, Emoji } from 'emoji-mart'
import { MentionsInput, Mention } from 'react-mentions'
import { MessageList, ReplyMessage, MessageBox } from 'react-chat-elements'
import { confirmAlert } from 'react-confirm-alert'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { faHeart, faBold, faItalic, faCheckCircle, faShare, faStrikethrough, faUserTimes, faVolumeMute, faThumbtack, faUnlink, faCommentDots, faVolumeUp, faTimes, faUserCheck, faUserLock, faTrash, faStopwatch, faReply, faAngleRight  } from '@fortawesome/free-solid-svg-icons'

import fire from '../../../firebase'
import { formatDate } from '../../../utils/formatDate'
import publishMessage from '../../../utils/publishMessage'

import { AuthContext } from '../../../providers/AuthProvider'
import { AdminContext } from '../../../providers/AdminProvider'
import { RoomContext } from '../../../providers/RoomProvider'

import ChatHeader from '../ChatHeader'

import adwAvatar from '../../../adw-avatar.png'

import 'react-confirm-alert/src/react-confirm-alert.css'
import 'emoji-mart/css/emoji-mart.css'
import 'react-chat-elements/dist/main.css'
import './index.css'

const ADW_URL = process.env.REACT_APP_ADW_URL || 'https://adwdev.xyz'

function ChatRoom({ room, threadMessage, isThread }) {
  const { user, senderId, sender, senderAvatar } = useContext(AuthContext)
  const { bannedUsers } = useContext(AdminContext)
  const { activeThread, setActiveThread } = useContext(RoomContext)

  const [slowMode, setSlowMode] = useState(false)
  const [slowModeHint, setSlowModeHint] = useState(false)

  const [autoScroll, setAutoScroll] = useState(true)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(true)
  const [newMessage, setNewMessage] = useState('')
  const [newMessageMentions, setNewMessageMentions] = useState([])
  const [privateSubscribers, setPrivateSubscribers] = useState([])
  const [selectedReplyMessage, setSelectedReplyMessage] = useState()
  const [messages, setMessages] = useState([])
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [paginationCursor, setPaginationCursor] = useState({})

  const [location, setLocation] = useState(null)
  const [questionWorkingOn, setQuestionWorkingOn] = useState(null)
  const [questionBiggestChallenge, setQuestionBiggestChallenge] = useState(null)
  const [questionLookingToMeet, setQuestionLookingToMeet] = useState(null)
  const [connectWithMe, setConnectWithMe] = useState(null)

  const inputRef = useRef()
  const senderRef = useRef()
  const messagesRef = useRef()
  const messagesListRef = useRef()
  const emojiPickerWrapperRef = useRef()
  const activeThreadRef = useRef()

  senderRef.current = sender
  messagesRef.current = messages
  activeThreadRef.current = activeThread

  const joinRoom = fire.functions.httpsCallable('joinRoom')

  const joinMixer = useCallback(() => {
    const messageTexts = [
      ":earth_americas: \b **Location:**",
      ":desktop_computer: \b **What** **I'm** **Currently** **Working** **On:**",
      ":warning: \b **Biggest** **Challenge** **Right** **Now:**",
      ":handshake: \b **Looking** **To** **Meet:**",
      ":wave: \b **Connect** **With** **Me:**",
    ];

    const message = [
      location !== null ? location : user?.metadata.location,
      questionWorkingOn !== null ? questionWorkingOn : user?.metadata.questionWorkingOn,
      questionBiggestChallenge !== null ? questionBiggestChallenge : user?.metadata.questionBiggestChallenge,
      questionLookingToMeet !== null ? questionLookingToMeet : user?.metadata.questionLookingToMeet,
      connectWithMe !== null ? connectWithMe : user?.metadata?.socials['linkedin'],
    ].reduce((acc, value, index) => {
      return value ? `${acc} \n ${messageTexts[index]} ${value}` : acc;
    }, "");

    handleSendMessage(message).catch(console.error)
  }, [sender, location, questionWorkingOn, questionBiggestChallenge, questionLookingToMeet, connectWithMe, user?.metadata])

  const joinMixerModal = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) =>
        <form className="react-confirm-alert-body" onSubmit={e => {
          e.preventDefault()
          joinMixer()
          onClose()
        }}>
          <h2>It's time to speed network with other attendees!</h2>

          <p>Freshen up your profile, share, explore and connect.</p>
          <p>Use each attendees social link to make sure you lock in connections for after Ad World Conf!</p>
          <p>Pro tip: Be specific with your profile, will make for better connections.</p>

          <label>🌎&nbsp; Location:</label>
          <input
            placeholder="e.g: Europa, Jupiter"
            defaultValue={user?.metadata.location}
            onChange={e => setLocation(e.target.value)}
          />

          <label>🖥&nbsp; What I'm Currently Working On</label>
          <input
            placeholder="e.g: Growing my YouTube audiences out"
            defaultValue={user?.metadata.questionWorkingOn}
            onChange={e => setQuestionWorkingOn(e.target.value)}
          />

          <label>⚠️&nbsp; Biggest Challenge Right Now</label>
          <input
            placeholder="e.g: Reformatting to Long form content"
            defaultValue={user?.metadata.questionBiggestChallenge}
            onChange={e => setQuestionBiggestChallenge(e.target.value)}
          />

          <label>🤝&nbsp; Looking To Meet</label>
          <input
            placeholder="e.g: Experienced YouTube Creators"
            defaultValue={user?.metadata.questionLookingToMeet}
            onChange={e => setQuestionLookingToMeet(e.target.value)}
          />

          <label>👋&nbsp; Connect With Me</label>
          <div className="inline-inputs">
            <select onChange={e => setConnectWithMe(e.target.value)} defaultValue={user?.metadata?.socials['linkedin'] || ''}>
              {
                ['Linkedin', 'Twitter', 'Facebook'].map(social =>
                  <option value={user?.metadata?.socials[social.toLowerCase()] || ''}>{social}</option>
                )
              }
            </select>
            <input
              type="url"
              placeholder="e.g: https://linkedin.com/in/xxxxxxx"
              defaultValue={connectWithMe || user?.metadata?.socials['linkedin'] || ''}
              onChange={e => setConnectWithMe(e.target.value)}
            />
          </div>

          <button>Join Mixer</button>
          <button className="react-confirm-alert-close" onClick={onClose}>Close</button>
        </form>,
      buttons: [],
      closeOnClickOutside: true,
      closeOnEscape: true,
    })
  }, [room, user?.metadata, location, questionWorkingOn, questionBiggestChallenge, questionLookingToMeet, connectWithMe, joinMixer])

  useEffect(() => {
    if (room?.id && senderId) {
      const activeUsers = room.activeUsers || {}

      if (!room.locked) {
        if (activeUsers[senderId] !== sender) {
          joinRoom({ chatId: room.id }).catch(console.error)
        }

        if (room.mixer && !isThread && !Object.keys(activeUsers).includes(senderId)) {
          joinMixerModal()
        }
      }
    }
  }, [room, isThread, sender, senderId, joinMixerModal])

  useEffect(() => {
    const banned = user?.metadata?.banned
    const kicked = room?.kicked?.includes(senderId)
    const muted = room?.muted?.includes(senderId)

    if (banned || kicked || muted) {
      confirmAlert({
        title: `You've been ${muted ? 'muted' : 'banned'} from ${banned ? 'the chat' : 'this chat room'}!`,
        message: 'Please reach out to support if you think this is a mistake..',
        buttons: [
          {
            label: 'Close',
            onClick: () => {}
          },
        ]
      })
    }
  }, [senderId, user?.metadata?.banned, room?.kicked, room?.muted])

  useEffect(() => {
    let unsubscriber = null

    if (room && room.id && senderId) {
      const threadId = threadMessage ? threadMessage.id : null

      unsubscriber = fire.chatMessageRef(room.id, null, threadId).orderBy('timestamp', 'asc').limitToLast(20).onSnapshot(snapshot => {
        setLoadingMessages(false)
        snapshot.docChanges().forEach((change, index) => {
          const { doc, type } = change
          const data = doc.data()
          data.id = doc.id

          if (index === 0) {
            setPaginationCursor(doc)
          }

          if (data.deleted) {
            if (type !== 'added') {
              setMessages(
                messagesRef.current.filter(x => x.id !== doc.id)
              )

              if (doc.id === activeThreadRef.current?.message?.id) {
                setActiveThread(null)
              }
            }
          } else {
            if (type === 'added') {
              data.status = doc.metadata.hasPendingWrites ? 'waiting' : 'sent'
              addMessage(
                deserializeMessage(data)
              )
            }

            if (type === 'modified') {
              data.status = 'sent'
              updateMessage(
                doc.id,
                deserializeMessage(data)
              )
            }
          }
        })
      }, err => {
        console.log(`Encountered error: ${err}`)
      })

      inputRef.current?.focus()
    }

    return function cleanup() {
      unsubscriber && unsubscriber()

      setAutoScroll(true)
      setScrolledToBottom(false)
      setLoadingMessages(true)
      setNewMessage('')
      setNewMessageMentions([])
      setSelectedReplyMessage()
      setMessages([])
      setShowLoadMore(false)
      setAllMessagesLoaded(false)
      setShowEmojiPicker(false)
      setPaginationCursor({})
    }
  }, [room.id, senderId, threadMessage])

  const renderActions = message => {
    if (message.pinned) {
      return (
        <div className="message-actions-wrapper">
          <div className="message-actions message-actions-pinned">
            <div onClick={event => handleActionClick('reply-thread', message.raw, event)} className="message-actions-action">View Answers <FontAwesomeIcon icon={faCommentDots} /><span className="message-actions-tooltip">Reply in thread</span></div>
            {user.claims?.admin && <div onClick={event => handleActionClick('unpin', message.raw, event)} className="message-actions-action"><FontAwesomeIcon icon={faUnlink} color="#e52730" /><span className="message-actions-tooltip">Unpin</span></div>}
          </div>
        </div>
      )
    }

    const getReplyText = () => {
      switch (message.threadMessagesCount) {
        case 0:
          return <span>Reply</span>;
        case 1:
          return <span>{message.threadMessagesCount} Reply</span>;
        default:
          return <span>{message.threadMessagesCount} Replies</span>;
      }
    };

    return (
      <div className="message-actions-wrapper">
        <div className="message-actions">
          {
            message?.likes.includes(senderId)
              ? <div onClick={() => handleActionClick('unlike', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faHeart} color="#e52730" /><span className="message-actions-tooltip">Unlike</span></div>
              : <div onClick={() => handleActionClick('like', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faHeart} /><span className="message-actions-tooltip">Like</span></div>
          }

          {
            // <div onClick={() => handleActionClick('edit', message)} className="message-actions-action"><FontAwesomeIcon icon={faPen} /><span className="message-actions-tooltip">Edit</span></div>
          }

          {
            (user.claims?.admin || message.senderId === senderId) &&
              <div onClick={() => handleActionClick('delete', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faTrash} /><span className="message-actions-tooltip">Delete</span></div>
          }

          {user.claims?.admin && (
            bannedUsers?.includes(message.senderId)
              ? < div onClick={() => handleActionClick('unban', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faUserLock} color="#e52730" /><span className="message-actions-tooltip">Unblock from all rooms</span></div>
              : (
                <>
                  <div onClick={() => handleActionClick('ban', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faUserLock} /><span className="message-actions-tooltip">Block from all rooms</span></div>
                  {
                    room?.kicked?.includes(message.senderId)
                      ? <div onClick={() => handleActionClick('unkick', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faUserTimes} color="#e52730" /><span className="message-actions-tooltip">Unblock from this room</span></div>
                      : <div onClick={() => handleActionClick('kick', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faUserCheck} /><span className="message-actions-tooltip">Block from this room</span></div>
                  }
                </>
              )
            )
          }

          {
            user.claims?.admin &&
              (
                room?.muted?.includes(message.senderId)
                  ? <div onClick={() => handleActionClick('unmute', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faVolumeMute} color="#e52730" /><span className="message-actions-tooltip">Unmute user</span></div>
                  : <div onClick={() => handleActionClick('mute', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faVolumeUp} /><span className="message-actions-tooltip">Mute user</span></div>
              )
          }

          {
            !(room.mixer && !isThread) &&
              <div onClick={() => handleActionClick('reply', message)} className="message-actions-action"><FontAwesomeIcon icon={faReply} /><span className="message-actions-tooltip">Quote</span></div>
          }

          {
            !isThread &&
              <div onClick={() => handleActionClick('reply-thread', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faCommentDots} /><span className="message-actions-tooltip">Reply in thread</span></div>
          }

          {
            user.claims?.admin && !isThread && (
              pinnedMessage?.id === message.id
                ? <div onClick={event => handleActionClick('unpin', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faUnlink} color="#e52730" /><span className="message-actions-tooltip">Unpin</span></div>
                : <div onClick={() => handleActionClick('pin', message.raw)} className="message-actions-action"><FontAwesomeIcon icon={faThumbtack} /><span className="message-actions-tooltip">Pin</span></div>

            )
          }
        </div>

        {
          (message.likes?.length &&
            <div onClick={() => handleActionClick(message?.likes?.includes(senderId) ? 'unlike' : 'like', message.raw)} className="message-likes">
              <FontAwesomeIcon icon={faHeart} color={message?.likes?.includes(senderId) ? '#e52730' : null} /> &nbsp; {message.likes.length}
            </div>
          ) || null
        }

        {
          ((message.threadMessagesCount || (room.mixer && !isThread)) &&
            <div onClick={() => handleActionClick('reply-thread', message.raw)} className="message-thread-replies">
              {getReplyText()}
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          ) || null
        }
      </div>
    )
  }

  const handleActionClick = (type, message, event) => {
    if (event) {
      event.stopPropagation()
    }

    const threadId = threadMessage ? threadMessage.id : null
    const chatRef = fire.chatRef(room.id)
    const msgRef = fire.chatMessageRef(room.id, message.id, threadId)

    const toggleBannedForUser = fire.functions.httpsCallable('toggleBannedForUser')
    const toggleKickedForUser = fire.functions.httpsCallable('toggleKickedForUser')
    const toggleMutedForUser = fire.functions.httpsCallable('toggleMutedForUser')
    const leaveRoom = fire.functions.httpsCallable('leaveRoom')

    switch (type) {
      case 'like':
        msgRef
          .update({
            likes: fire.FieldValue.arrayUnion(senderId)
          })
          .catch(console.error)
          break
      case 'unlike':
        msgRef
          .update({
            likes: fire.FieldValue.arrayRemove(senderId)
          })
          .catch(console.error)
          break
      case 'delete':
        confirmAlert({
          message: 'Are you sure you want to delete this message?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                msgRef
                  .update({
                    deleted: true
                  })
                  .then(() => {
                    if (room.mixer && !isThread) {
                      leaveRoom({ chatId: room.id }).catch(console.error)
                    }
                  })
                  .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'ban':
        confirmAlert({
          message: 'Are you sure you want to block this user from all rooms?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleBannedForUser({
                  uid: message.senderId,
                  banned: true,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'unban':
        confirmAlert({
          message: 'Are you sure you want to unblock this user from all rooms?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleBannedForUser({
                  uid: message.senderId,
                  banned: false,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'kick':
        confirmAlert({
          message: 'Are you sure you want to block this user from this room?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleKickedForUser({
                  uid: message.senderId,
                  roomId: room.id,
                  kicked: true,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'unkick':
        confirmAlert({
          message: 'Are you sure you want to unblock this user from this room?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleKickedForUser({
                  uid: message.senderId,
                  roomId: room.id,
                  kicked: false,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'mute':
        confirmAlert({
          message: 'Are you sure you want to mute this user?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleMutedForUser({
                  uid: message.senderId,
                  roomId: room.id,
                  muted: true,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'unmute':
        confirmAlert({
          message: 'Are you sure you want to unmute this user?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                toggleMutedForUser({
                  uid: message.senderId,
                  roomId: room.id,
                  muted: false,
                })
                .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'reply':
        setSelectedReplyMessage(message)
        break
      case 'reply-thread':
        setActiveThread({ room, message: {...message, pinned: false} })
        break
      case 'pin':
        confirmAlert({
          message: 'Are you sure you want to pin this message?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                chatRef
                  .update({
                    pinnedMessage: {
                      ...message,
                      pinned: true
                    }
                  })
                  .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      case 'unpin':
        confirmAlert({
          message: 'Are you sure you want to unpin this message?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                chatRef
                  .update({
                    pinnedMessage: null
                  })
                  .catch(console.error)
              }
            },
            { label: 'No' }
          ]
        })
        break
      default:
        break
    }
  }

  const parseMessage = (id, text, plain = false) => {
    const parsed = text.split(/\s(?![^\[]*\])/).map((raw, index) => {
      const newLineMatches = raw.match(/^\\\\n$/)
      const emojiMatches = raw.match(/^(:[a-z|0-9|\+|\-|_]+:)$/)
      const urlMatches = raw.match(/^(?!\.)(?!.*\.$)(?!.*?\.\.)(?:https?:\/\/|www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.(?:xn--)?[a-z0-9-]{2,20}\b(?:[-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*)/i)
      const mentionMatches = raw.match(/@\[(.+)\]\((.+)\)/)

      if (newLineMatches){
        return plain ? "\n" : <br />
      } if (emojiMatches) {
        const emoji = emojiMatches[1]
        return plain ? emoji : <Emoji emoji={emoji} size={20} key={`emoji-${id}-${index}`} />
      } else if (urlMatches) {
        const url = urlMatches[1]
        return plain ? `${url} ` : <><a href={`https://${url}`} target="_blank" rel="noopener noreferrer">{url}</a>{' '}</>
      } else if (mentionMatches) {
        // const id = mentionMatches[2]
        const username = mentionMatches[1]
        return plain ? `${username} ` : <><b>{username}</b>{' '}</>
      } else {
        let bold = /\*{2}(.+)\*{2}/.test(raw)
        let italic = /_{2}(.+)_{2}/.test(raw)
        let strikethrough = /~{2}(.+)~{2}/.test(raw)
        const text = raw.replace(/[*_~]{2,6}/g, '')
        let html = text

        if (bold) {
          html = <b>{html}</b>
        }

        if (italic) {
          html = <i>{html}</i>
        }

        if (strikethrough) {
          html = <strike>{html}</strike>
        }

        return plain ? `${text} ` : <>{html}{' '}</>
      }
    })

    return plain ? parsed.join(' ') : parsed
  }

  const deserializeMessage = msg => {
    const msgSender = (room.users || room.activeUsers || [])[msg.senderId] || msg.sender
    const date = msg.timestamp.toDate()
    const obj = {
      className: [
        !msg.pinned && (msg.threadMessagesCount || (room.mixer && !isThread)) ? 'message-has-replies' : '',
        !msg.pinned && msg.likes && msg.likes.length ? 'message-has-likes' : ''
      ],
      key: msg.id,
      id: msg.id,
      position: 'left',
      type: 'text',
      status: msg.status,
      senderId: msg.senderId,
      senderExternalId: msg.senderExternalId,
      title: <>
        {msgSender}
        {msg.verified && <FontAwesomeIcon icon={faCheckCircle} style={{ width: '14px', marginLeft: '5px', color: "#146df1" }} />}
      </>,
      plainTitle: msgSender,
      text: parseMessage(msg.id, msg.text),
      plainText: parseMessage(msg.id, msg.text, true),
      date: date,
      dateString: formatDate(date),
      reply: msg.reply || null,

      avatar: msg.avatar || `https://ui-avatars.com/api/?name=${msg.sender}&background=random`,
      focus: msg.mentions.includes(senderId) || msg.reply?.title === sender,

      pinned: msg.pinned || false,
      likes: msg.likes || [],
      threadMessagesCount: msg.threadMessagesCount || 0,
      raw: msg
    }

    return obj
  }

  const addMessage = msg => {
    setMessages([
      ...messagesRef.current,
      msg
    ])
  }

  const updateMessage = (id, patch) => {
    return setMessages(
      messagesRef.current.map(msg => {
        if (msg.id === id) {
          return Object.assign(msg, patch)
        }

        return msg
      })
    )
  }

  const getSubscribers = async () => {
    if (room) {
      if (!room.public && privateSubscribers.length > 0) {
        return privateSubscribers
      }

      const mentions = (room.public ? newMessageMentions : Object.keys(room.users)).filter(id => id !== senderId)

      if (mentions.length > 0) {
        const { docs } = await fire.firestore.collection('users').where('id', 'in', mentions).get()
        const result = docs.map(doc => doc.get('fcmToken'))

        if (!room.public) {
          setPrivateSubscribers(result)
        }

        return result
      }
    }

    return []
  }

  const handleSendMessage = async (_newMessage = '') => {
    if (slowMode) {
      setSlowModeHint(true)
      return false
    }

    setSlowMode(true)

    setTimeout(() => {
      setSlowMode(false)
      setSlowModeHint(false)
    }, room.public ? 5000 : 500)

    setAutoScroll(true)

    const text = _newMessage.trim() || newMessage.trim().slice(0, 600)
    setNewMessage('')

    const subscribers = await getSubscribers()

    const senderExternalId = user?.metadata?.externalId || null

    const formatNewLines = x => x.replaceAll(/[\r\n]{2,}/g, " \\\\n \\\\n ").replaceAll(/[\r\n]{1}/g, " \\\\n ")

    if (text) {
      const obj = {
        text: formatNewLines(text),
        sender,
        senderId,
        senderExternalId,
        verified: user?.claims?.admin || false,
        avatar: senderAvatar,
        mentions: newMessageMentions,
        subscribers,
        timestamp: fire.TimeStamp.now(),
      }

      // TODO: append also sender into subs
      if (selectedReplyMessage) {
        obj.reply = {
          id: selectedReplyMessage.id,
          title: selectedReplyMessage.plainTitle,
          titleColor: '#8717ae',
          message: formatNewLines(selectedReplyMessage.plainText),
          photoURL: selectedReplyMessage.avatar
        }
        setSelectedReplyMessage()
      }

      const threadId = threadMessage ? threadMessage.id : null

      fire
        .chatMessageRef(room.id, null, threadId)
        .add(obj)
        .then(({ id }) => updateMessage(id, { status: 'sent' }))
        .catch(console.error)

      publishMessage({
        table: 'chat_messages',
        data: {
          room_id: room.id,
          room_name: room.name,
          datetime: '',
          user_id: senderExternalId,
        },
      });
    }
  }

  const addEmoji = emoji => {
    const input = inputRef.current
    const { value, selectionEnd } = input
    setNewMessage([value.slice(0, selectionEnd), emoji.colons, value.slice(selectionEnd)].join(' '))
    input.focus()
  }

  const addFormating = style => {
    const styles = {
      bold: '**',
      italic: '__',
      strikethrough: '~~'
    }

    if (Object.keys(styles).includes(style)) {
      const input = inputRef.current
      const { value, selectionStart, selectionEnd } = input
      setNewMessage(
        [
          value.slice(0, selectionStart),
          styles[style],
          value.slice(selectionStart, selectionEnd).split(' ').join(`${styles[style]} ${styles[style]}`),
          styles[style],
          value.slice(selectionEnd)
        ].join('')
      )
      input.focus()
    }
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }

  // const loadMore = (_, scrollToMessageId) => {
  const loadMore = () => {
    setLoadingMessages(true)
    setShowLoadMore(false)

    const threadId = threadMessage ? threadMessage.id : null

    fire.chatMessageRef(room.id, null, threadId).orderBy('timestamp', 'asc').endBefore(paginationCursor).limitToLast(20).get().then(snapshots => {
      if (snapshots.size === 0) {
        setAllMessagesLoaded(true)
      } else {
        var paginationCursorUpdated = false

        snapshots.forEach(snapshot => {
          if (snapshot.exists) {
            if (!paginationCursorUpdated) {
              setPaginationCursor(snapshot)
              paginationCursorUpdated = true
            }

            const data = snapshot.data()
            const obj = { id: snapshot.id, ...data }

            // if (scrollToMessageId === obj.id) {
            //   obj.focus = true
            // }

            if (!obj.deleted) {
              addMessage(
                deserializeMessage(obj)
              )
            }
          }
        })

        // if (!scrollToMessageId) {
        //   messagesListRef.current.mlistRef.scrollTop = 0
        // }
      }

      setLoadingMessages(false)
    })
  }

  const scrollToMessage = target => {
    if (messages.some(m => m.id === target.reply.id)) {
      updateMessage(target.reply.id, { focus: true })
    } else {
      messagesListRef.current.mlistRef.scrollTop = 0
    }
  }

  const emojiPickerCollapser = e => {
    if (showEmojiPicker && !emojiPickerWrapperRef.current.contains(e.target)) {
      setShowEmojiPicker(false)
    }
  }

  const lockRoom = e => {
    confirmAlert({
      message: 'Are you sure you want to lock this room?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            fire.chatRef(room.id).update({
              locked: true
            })
            .catch(console.error)
          }
        },
        { label: 'No' }
      ]
    })
  }

  const unlockRoom = e => {
    confirmAlert({
      message: 'Are you sure you want to unlock this room?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            fire.chatRef(room.id).update({
              locked: false
            })
            .catch(console.error)
          }
        },
        { label: 'No' }
      ]
    })
  }

  if (!room?.id) {
    return null
  }

  let pinnedMessage
  if (room.pinnedMessage) {
    pinnedMessage = deserializeMessage(room.pinnedMessage)
    pinnedMessage = {
      ...pinnedMessage,
      renderAddCmp: () => renderActions(pinnedMessage)
    }
  }

  return (
    <div onClick={emojiPickerCollapser} className={`container-chat ${isThread ? 'thread-chat' : ''}`}>
      <ChatHeader room={room} isThread={isThread} lockRoom={lockRoom} unlockRoom={unlockRoom} />

      {
        isThread &&
        <div>
          <MessageBox
            {...deserializeMessage(threadMessage)}
            className=""
            status="sent"
          />

          {
            (!loadingMessages && messages.length) ?
              <div className="thread-chat_replies">
                <span>{ messages.length } Replies</span>
              </div>
            : null
          }
        </div>
      }

      {
        room.public && !isThread && (
          pinnedMessage?.id ?
            <div className="pinned-message-wrapper" onClick={() => handleActionClick('reply-thread', pinnedMessage.raw)}>
              <div className="pinned-message-info">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"/></svg></span>
                Pinned Message
              </div>
              <MessageBox {...pinnedMessage} status="sent" />
            </div>
          :
            !room.mixer && (
              <div className="pinned-message-wrapper">
                <div className="pinned-message-info">
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z" /></svg></span>
                  Pinned Message
                </div>
                <MessageBox
                  avatar={adwAvatar}
                  title="ADW Team"
                  text={
                    room.locked ?
                      room.messagesCount > 0 ?
                        <>
                          Welcome to {room.name}:<br />
                          The Q&A has finished but you can still browse around the past questions that were asked by other attendees.
                        </>
                      :
                        <>
                          Welcome to {room.name}:<br />
                          You will be able to start adding in questions once the Speaker’s presentation begins.<br/>
                          To find out when this kicks off please refer to the schedule.
                        </>
                    :
                      <>
                        Welcome to {room.name}:<br />
                        Ask any question you have for the speaker - we’ll try and get through them all!<br />
                        Show some love to any other questions you like to upvote them to be answered.<br />
                        If you didn’t catch their talk, yes replays will be available after the show!
                      </>
                  }
                  dateString="a few moments ago"
                  status="sent"
                />
              </div>
            )
        )
      }

      {showLoadMore && (
        allMessagesLoaded ? (
          <p className="rce-all-messages-loaded">
            All messages loaded!
          </p>
        ) : (
          <button className="rce-load-more" onClick={loadMore}>
            Load More
          </button>
        )
      )}

      {loadingMessages && (
        <div className="spinner"><div></div><div></div><div></div><div></div></div>
      )}

      <MessageList
        ref={messagesListRef}
        lockable={false}
        // downButtonBadge={messages.length}
        toBottomHeight={autoScroll || !scrolledToBottom ? Infinity : 0}
        dataSource={messages.map(x => ({ ...x, renderAddCmp: () => renderActions(x)})).sort((a, b) => Date.parse(a.date) - Date.parse(b.date))}
        onReplyMessageClick={scrollToMessage}
        onMessageFocused={({ id }) => updateMessage(id, { focus: false })}
        onTitleClick={message => {
          if (message.senderExternalId) {
            window.open(`${ADW_URL}/app/user-profile/${message.senderExternalId}`, '_blank')
          }
        }}
        onScroll={e => {
          const reachedBottom = e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight

          if (scrolledToBottom) {
            setAutoScroll(reachedBottom)
          } else if (!loadingMessages && reachedBottom) {
            setScrolledToBottom(true)
          }

          setShowLoadMore(e.target.scrollTop === 0 && messages.length > 0)
        }}
        notch={false}
      />

      <div ref={emojiPickerWrapperRef} style={{ opacity: showEmojiPicker ? 1 : 0, transition: 'opacity 0.33s ease', pointerEvents: showEmojiPicker ? 'initial' : 'none', zIndex: 999 }}>
        <Picker title="Pick your emoji.." emoji="point_up" onSelect={addEmoji} style={{ position: 'absolute', left: '15px', borderRadius: 0, border: '2px solid #d5dee1', bottom: selectedReplyMessage ? '145px' : '88px' }} />
      </div>

      {
        selectedReplyMessage &&
        <div className="rce-container-reply">
          <ReplyMessage
            photoURL={selectedReplyMessage.avatar}
            title={selectedReplyMessage.title}
            titleColor="#8717ae"
            message={selectedReplyMessage.text}
          />
          <button onClick={() => setSelectedReplyMessage()} className="rce-reply-cancel">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      }

      <div>
        <div className="emoj-mvm" onClick={toggleEmojiPicker}>
          <FontAwesomeIcon icon={faSmile} />
        </div>

        <MentionsInput
          placeholder="Message"
          className="mention-input"
          value={room.locked ? `${room.mixer ? 'Mixer' : 'Q&A'} is not live!` : newMessage}
          disabled={room.locked}
          // onChange={e => setNewMessage(e.target.value)}
          allowSuggestionsAboveCursor={true}
          inputRef={inputRef}
          singleLine={false}
          allowSpaceInQuery={true}
          maxLength={600}
          onKeyPress={e => {
            if (e.shiftKey && e.key === 'Enter') {
              return true
            }

            if (e.key === 'Enter') {
              handleSendMessage()
              e.preventDefault()
              return false
            }
          }}
          onChange={(event, newValue, newPlainTextValue, mentions) => {
            setNewMessage(newValue)
            setNewMessageMentions(mentions.map(m => m.id))
          }}
        >
          <Mention
            trigger="@"
            appendSpaceOnAdd={true}
            data={
              (search, cb) => {
                cb(
                  Object
                    .keys(room.activeUsers)
                    .filter(id => id !== senderId && room.activeUsers[id].toLowerCase().includes(search.toLowerCase()))
                    .map(id => ({ id, display: `@${room.activeUsers[id]}`}))
                )
              }
            }
          />
        </MentionsInput>

        <div className="slow-mode">
          <FontAwesomeIcon className="slow-mode-icon" icon={faStopwatch} />
          <span className={`slow-mode-tooltip ${slowModeHint && 'shake'}`} key={slowModeHint ? new Date() : 'slow-mode-tooltip'}>
            Slow mode! You can only send<br />a message every {room.public ? 5 : 0.5 } seconds!
          </span>
        </div>

        <div className="text-message-options">
          <FontAwesomeIcon icon={faBold} onClick={() => addFormating('bold')} />
          <FontAwesomeIcon icon={faItalic} onClick={() => addFormating('italic')} />
          <FontAwesomeIcon icon={faStrikethrough} onClick={() => addFormating('strikethrough')} />
          <FontAwesomeIcon icon={faShare} onClick={() => handleSendMessage()} />
        </div>
      </div>
    </div>
  )
}

export default ChatRoom
