import React, { useState, useContext, useEffect } from 'react'

import fire from '../firebase'

import { AuthContext } from './AuthProvider'

export const AdminContext = React.createContext({
  bannedUsers: [],
  setBannedUsers: () => {},
})

const AdminProvider = props => {
  const { user } = useContext(AuthContext)
  const [bannedUsers, setBannedUsers] = useState([])

  useEffect(() => {
    let unsubscriber;
    if (user?.claims?.admin) {
      unsubscriber = fire.firestore.collection('users').where('banned', '!=', null).onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change, index) => {
          const { doc, type } = change
          const data = doc.data()
          data.id = doc.id

          if ((type === 'added' || type === 'modified') && data.banned) {
            setBannedUsers(prevState => [...prevState, data.id])
          }

          if (type === 'removed' || (type === 'modified' && !data.banned)) {
            setBannedUsers(prevState => prevState.filter(x => x !== data.id))
          }
        })
      })
    }

    return function cleanup() {
      unsubscriber && unsubscriber()
    }
  }, [user?.claims?.admin])

  return (
    <AdminContext.Provider value={{
      bannedUsers,
    }}>
      {props.children}
    </AdminContext.Provider>
  )
}

export default AdminProvider
