export const formatDate = date => {
  const now = new Date()
  const today = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  const timeFormated = new Intl.DateTimeFormat([], { timeStyle: 'short', hour12: true }).format(date)

  if (Date.parse(date) < today) {
    const dateFormated = new Intl.DateTimeFormat([], { dateStyle: 'medium' }).format(date)
    return `${dateFormated} ${timeFormated}`
  } else {
    return timeFormated
  }
}
