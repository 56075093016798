import React, { useEffect, useState, useMemo } from 'react'

export const RoomContext = React.createContext({
  rooms: [],
  setRooms: () => {},

  activeRoom: {},

  activeRoomId: null,
  setActiveRoomId: () => {},

  activeThread: null,
  setActiveThread: () => {},
})

const RoomProvider = props => {
  const [rooms, setRooms] = useState([])
  const [activeRoomId, setActiveRoomId] = useState(null)
  const [activeThread, setActiveThread] = useState(null)

  const activeRoom = useMemo(() => (
    rooms.find(x => x.id === activeRoomId) || {}
  ), [rooms, activeRoomId])

  useEffect(() => {
    const listener = window.onmessage = event => {
      const payload = event.data

      if (payload.externalCommand === 'go') {
        if (payload.path) {
          setActiveRoomId(payload.path)
        } else {
          setActiveRoomId(null)
          setActiveThread(null)
        }
      }
    }

    return function cleanup() {
      window.removeEventListener('onmessage', listener)
    }
  }, [rooms])

  return (
    <RoomContext.Provider value={{
      rooms,
      setRooms,
      activeRoom,
      setActiveRoomId,
      activeThread,
      setActiveThread,
    }}>
      {props.children}
    </RoomContext.Provider>
  )
}

export default RoomProvider
