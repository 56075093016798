import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons'

import './index.css'

import firebase from '../../../firebase'
import { AuthContext } from '../../../providers/AuthProvider'
import { RoomContext } from '../../../providers/RoomProvider'

import thrasioPNG from '../../../thrasio.png'

const ChatHeader = ({ room, isThread, lockRoom, unlockRoom }) => {
  const { toggleMenu, user, senderId } = useContext(AuthContext)
  const { setActiveThread, activeRoom } = useContext(RoomContext)
  const [recipient, setRecipient] = useState()

  const socialIcons = {
    linkedin: <FontAwesomeIcon icon={faLinkedin} color="#0e76a8" style={{ marginLeft: 10 }} />,
    facebook: <FontAwesomeIcon icon={faFacebook} color="#3b5998" style={{ marginLeft: 10 }} />,
    twitter: <FontAwesomeIcon icon={faTwitter} color="#00acee" style={{ marginLeft: 10 }} />,
  }

  useEffect(() => {
    if (activeRoom?.public) {
      setRecipient()
    } else if (senderId && activeRoom?.users) {
      const { users } = activeRoom
      const recipientId = Object.keys(users).find(x => x !== senderId)
      firebase.userRef(recipientId).get().then(snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data()
          setRecipient(data)
        }
      })
    }
    firebase.userRef()
  }, [senderId, activeRoom])

  return (
    <div className="chat_header">
      { !isThread &&
        <div className="chat_lvl_1_header_title">
          <h3>
            <span className="sidebar-collapse-open" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBars} />
            </span>

            {recipient?.avatar && <img alt={room.name} src={recipient.avatar} />}

            {room.name}

            <span style={{float: 'right'}}>
              {Object.keys(recipient?.socials || {})?.map((name) =>
                recipient?.socials[name] &&
                  <a href={recipient?.socials[name]} target="_blank" rel="noopener noreferrer">
                    {socialIcons[name]}
                  </a>
              )}
            </span>

            {user?.claims?.admin && room.public && (
              room.locked ?
                <span className="chat_lock" onClick={unlockRoom}>
                  <FontAwesomeIcon icon={faLock} color="#e52730" title="Room Locked" />
                </span>
              :
                <span className="chat_lock" onClick={lockRoom}>
                  <FontAwesomeIcon icon={faLockOpen} color="#000" title="Room Unlocked" />
                </span>
              )
            }
          </h3>
        </div>
      }

      <div className="chat_lvl_2_header_title" style={isThread && {height: 64}}>
        {
          isThread ?
            <div className="chat-thread_lvl_2_header_wrapper">
              <h4>Thread</h4>
              <span className="chat-thread_close-btn" onClick={() => setActiveThread(null)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          :
            <div className="chat-powerd_by_wrapper">
              <div className="chat_powered_by">
                <span className="chat_powered_by_intro">POWERED BY</span>
                  <span className="chat_powered_by_logo">
                    <a href="https://adworld.online/app/booth/792" target="_blank" rel="noopener noreferrer">
                      <img src={thrasioPNG} alt="Thrasio" />
                    </a>
                  </span>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default ChatHeader
