import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Loading from '../components/Loading'

import firebase from '../firebase'

export const AuthContext = React.createContext()

const AuthProvider = props => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const [sender, setSender] = useState('')
  const [senderId, setSenderId] = useState('')
  const [senderAvatar, setSenderAvatar] = useState('')
  const [isCollapse, setCollapse] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const toggleMenu = () => setCollapse(!isCollapse)

  const params = new URLSearchParams(useLocation().search)
  const email = params.get('email')
  const password = params.get('password')
  const fcmToken = params.get('fcmToken')
  const {localStorage} = window

  useEffect(() => {
    async function effect() {
      const currentUserEmail = localStorage.getItem('chat-user-email')
      console.log(currentUserEmail)
      if (email && password) {
        if (currentUserEmail !== email) {
          console.log('logging out user')
          setIsAuthenticated(false)
          await firebase
            .auth
            .signOut()
            .then(console.log)
            .catch(console.log)
        }

        firebase
          .auth
          .signInWithEmailAndPassword(email, password)
          .then(x => console.log(x))
          .catch(err => {
            if (err.code) {
              window.top.postMessage({
                error: err.code,
              }, '*')
            }

            console.log(err)
          })
      }
    }
    effect()
  }, [email, password])

  useEffect(() => {
    let userUnsubscriber = null

    const authUnsubscriber = firebase.auth.onAuthStateChanged(async user => {
      setUser(user)
      setSenderId(user?.uid)
      setSender(user?.displayName)
      setSenderAvatar(user?.photoURL)
      setIsAuthenticated(!!user)

      if (user?.uid) {
        localStorage.setItem('chat-user-email', user.email)
        userUnsubscriber = firebase
          .userRef(user.uid)
          .onSnapshot(async snapshot => {
            const token = await firebase.auth.currentUser.getIdTokenResult(true)
            const claims = token.claims
            const metadata = snapshot.data()
            // console.log(claims)
            // console.log(metadata)

            const currentFcmToken = metadata.fcmToken

            if (currentFcmToken !== fcmToken) {
              const subscribeUser = firebase.functions.httpsCallable('subscribeUser')
              await subscribeUser({ fcmToken }).catch(console.error)
            }

            setUser({
              ...user,
              metadata,
              claims
            })
          })
      }
    })

    return function cleanup() {
      authUnsubscriber()
      userUnsubscriber && userUnsubscriber()

      setUser()
      setSender('')
      setSenderId('')
      setSenderAvatar('')
      setIsAuthenticated(false)
    }
  }, [])

  if (isAuthenticated) {
    return (
      <AuthContext.Provider
        value={{
          user,
          senderId,
          sender,
          senderAvatar,
          isAuthenticated,
          toggleMenu,
          loading,
          setLoading,
          isCollapse,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    )
  }

  return <Loading />
}
export default AuthProvider
