import React from 'react'

import './index.css'

const Loading = () => (
  <div className="loading">
    <img
      src="https://adworldconference.com/images/adworld-logo/logo-light.svg"
      className="loading-logo"
      alt="Ad World Conf"
      title="Ad World Conf"
    />
    <h4 className="loading-text">Loading..</h4>
  </div>
)

export default Loading
