import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/app'
import 'firebase/functions'

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyDjd6LNmwTLFIe3GcVvJdeMfhz6XgD3Y8s',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'adw4-chat-dev.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'adw4-chat-dev',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'adw4-chat-dev.appspot.com',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:945889607842:web:42282bfecf6b17067317fb',
}

if (process.env.REACT_APP_FIREBASE_DB_URL) {
  config.databaseURL = process.env.REACT_APP_FIREBASE_DB_URL
}

firebase.initializeApp(config)

firebase.analytics()
firebase.auth()

const firestore = firebase.firestore()
const functions = firebase.app().functions('us-east4')
const auth = firebase.auth()

firestore.enablePersistence()

const userRef = userId => {
  let ref = firestore.collection('users')

  if (userId) {
    ref = ref.doc(userId)
  }

  return ref
}

const chatRef = chatId => {
  let ref = firestore.collection('chats')

  if (chatId) {
    ref = ref.doc(chatId)
  }

  return ref
}

const chatUserRef = (chatId, userId) => {
  let ref = chatRef(chatId).collection('users')

  if (userId) {
    ref = ref.doc(userId)
  }

  return ref
}

const chatMessageRef = (chatId, messageId, threadId) => {
  let ref = chatRef(chatId).collection('messages')

  if (messageId && threadId) {
    ref = ref.doc(threadId).collection('thread').doc(messageId)
  } else if (threadId) {
    ref = ref.doc(threadId).collection('thread')
  } else if (messageId) {
    ref = ref.doc(messageId)
  }

  return ref
}

export default {
  firebase,
  firestore,
  functions,
  auth,
  TimeStamp: firebase.firestore.Timestamp,
  FieldValue: firebase.firestore.FieldValue,

  userRef,
  chatRef,
  chatUserRef,
  chatMessageRef
}
