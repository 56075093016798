import React, { useState, useRef, useContext, useEffect } from 'react'

import { AuthContext } from '../../providers/AuthProvider'

import fire from '../../firebase'

import './index.css'

const ADW_URL = process.env.REACT_APP_ADW_URL || 'https://adworld.online'

const toggleBannedForUser = fire.functions.httpsCallable('toggleBannedForUser')
const toggleKickedForUser = fire.functions.httpsCallable('toggleKickedForUser')
const toggleMutedForUser = fire.functions.httpsCallable('toggleMutedForUser')

const Admin = () => {
  const { isAuthenticated, user } = useContext(AuthContext)

  console.log(user)

  const [users, setUsers] = useState([])
  const [rooms, setRooms] = useState({})

  const usersRef = useRef()
  usersRef.current = users

  useEffect(() => {
    fire.chatRef().where('public', '==', true).get().then(snapshot => {
      let roomsMap = {}
      let mutesMap = {}
      let kicksMap = {}

      snapshot.docs.forEach(doc => {
        if (doc.exists) {
          const data = doc.data()
          const roomId = doc.id
          
          roomsMap[roomId] = data
          
          data.muted?.forEach(uid =>
            mutesMap[uid] = [...(mutesMap[uid] || []), roomId]
          )
            
          data.kicked?.forEach(uid =>
            kicksMap[uid] = [...(kicksMap[uid] || []), roomId]
          )
        }
      })

      setRooms(roomsMap)

      const idx = new Set([
        ...Object.keys(mutesMap),
        ...Object.keys(kicksMap)
      ])

      Promise
        .all([...idx].map(id => fire.userRef(id).get()))
        .then(snapshots => {
          const result = snapshots.map(snapshot => {
            if (snapshot.exists) {
              const uid = snapshot.id
              const data = snapshot.data()
              console.log(data)
              data.id = uid
              data.muted = mutesMap[uid] || []
              data.kicked = kicksMap[uid] || []
              return data
            }
            return null
          })
          console.log(result)
          setUsers([...usersRef.current, ...result])
        })
    })

    fire.userRef().where('banned', '==', true).get().then(snapshot => {
      let result = []
      snapshot.docs.forEach(doc => {
        const data = doc.data()
        result.push(data)
      })
      setUsers([...usersRef.current, ...result])
    })
  }, [])

  const reloadAfterThreeSeconds = () => {
    alert('Please wait a few seconds, page will refresh automatically..')
    setTimeout(() =>
      window.location.reload(),
      3000
    )
  }

  if (!isAuthenticated || !user?.claims?.admin) {
    return null
  }

  return (
    <div className="admin">
      <h1 style={{paddingLeft: 10}}>ADW Chat Admin Panel <i style={{fontSize: 12}}>super ugly but functional 🙃</i></h1>
      <table>
        <thead>
          <tr>
            <th>ADW Profile</th>
            <th>Muted in specific rooms</th>
            <th>Blocked in specific rooms</th>
            <th>Globally Blocked</th>
          </tr>
        </thead>
        <tbody>
          {
            users.map(x =>
              <tr key={x.id}>
                <td><a href={`${ADW_URL}/app/user-profile/${x.externalId}`}>{x.name}</a></td>
                <td>
                  <ul>
                    {x.muted?.map(roomId =>
                      <li
                        onClick={() =>
                          toggleMutedForUser({
                            uid: x.id,
                            roomId,
                            muted: false,
                          })
                          .then(reloadAfterThreeSeconds)
                          .catch(console.error)
                        }
                      >
                        Unmute from <u>Q&A with {rooms[roomId]?.name}</u>
                      </li>
                    )}
                  </ul>
                </td>
                <td>
                  <ul>
                    {x.kicked?.map(roomId =>
                      <li
                        onClick={() =>
                          toggleKickedForUser({
                            uid: x.id,
                            roomId,
                            kicked: false,
                          })
                          .then(reloadAfterThreeSeconds)
                          .catch(console.error)
                        }
                      >
                        Unblock from <u>Q&A with {rooms[roomId]?.name}</u>
                      </li>
                    )}
                  </ul>
                </td>
                <td>
                  <u
                    onClick={() =>
                      toggleBannedForUser({
                        uid: x.id,
                        banned: !x.banned,
                      })
                      .then(reloadAfterThreeSeconds)
                      .catch(console.error)
                    }
                  >
                    {x.banned ? 'Unban globally' : 'Ban globally'}
                  </u>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )
}

export default Admin